import React, { useState,useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import '../../commons/voucher.css'
import commaNumber from 'comma-number';
let format = commaNumber.bindWith(',', '.')
const VoucherBody = ({salesOrderData,institution,authInfo})=>{
    const classes = useStyles();
  

         let [salesOrder,salesOrderSet] = useState([])
          let [salesOrderDetails,salesOrderDetailsSet] = useState([])

          let [is_serial,is_serial_set] = useState('')
          let [is_cal_type,is_cal_type_set] = useState('')

        useEffect(()=>{
            salesOrderSet(salesOrderData)
            
            is_serial_set(salesOrderData.is_serial)
            is_cal_type_set(salesOrderData.is_cal_type)

            if(salesOrderData.details!=undefined){
                salesOrderDetailsSet(salesOrderData.details)
            }

            console.log(authInfo)
            },[salesOrderData])

         


      return(
          <>

{
                        authInfo.is_auto_challan == 'yes'?
                         <table className={'invoice-table-challan'}> 
                         <thead>
                             <tr>
                               <td  align="left"  width="1%">SL</td>
                               <td align="left" width="15%"> Item Name</td>
                               {
                               is_serial=='yes'?(<>
                             <td align="left"   width="15%">Serials</td>
               
                               </>):''
                             }
                             
                             {
                               authInfo.is_warehouse == 'yes'?(<>
                                 <td align="left"   width="15%">Warehouse</td>
                               </>):''
                             }
                             <td align="right"  width="10%"> QTY</td>
                          
                             <td align="right"  width="10%"> SFT</td>

                           
                             </tr>
                               
                            
                         </thead>
                         <tbody>
                       
                                   {
                                       salesOrderDetails.map((item,ind)=>(<>
                                              <tr>
                                                <td  style={{textAlign:'left',fontSize:'15px'}}>{parseFloat(1)+ind}</td>
                                                <td  style={{textAlign:'left'}}>{item.item_name} <br/>
                                <p style={{    marginLeft: '7px',
    marginTop: 0,
    marginBottom: '2px',
    fontWeight: ''}}>  {
                              item.size_id != 0 ?   item.size + "' X " + item.print_size +"' "   : '' 
                                 }</p>
                                  </td>
                                                {
                                                   is_serial == 'yes'?(<>
                                                   <td align="left"  style={{textAlign:'left',fontSize:'15px'}} width="15%">
                                                   {
                                                       item.is_serial == 'yes'?(<>
                                                           {
                                                           <>
                                                           ({item.serials.map((serial)=>(
                                                       <> <span>{serial.serial_number}</span>, </> 
                                                       ))})
                                                       </>
                                                        }
                                                       </>):''
                                                   }
                                                 </td>
                                   
                                                  </>):''
                                                 }
               
                                 {
                                  authInfo.is_warehouse =='yes'?(<>
                                         <td align="left" style={{textAlign:'left',fontSize:'15px'}}>{item.warehouse_name}</td>
                                  </>):''
                                }
                                            <td align="right">{item.size_qty}</td>
                                            <td align="right">{item.sft}</td>
                               
                               
               
                                               </tr>
                                       </>))
                                   }
                         
                               
                          
               
                              
                             
               
                             
                         </tbody>
                       </table>
                        : <p style={{
                          textAlign: 'center',
                          whiteSpace: 'pre-line',
                          fontWeight: 'bold',
                          fontSize: '25px'
                        }}>{salesOrderData.challan_text}</p>
                      }

     
          </>
      )
}


const useStyles = makeStyles((theme) => ({
    
    
  }));

export default VoucherBody;
